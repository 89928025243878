<template>
  <td v-tooltip="lang.attentionRequests.navbar.expropriate[languageSelected]">
    <a>
      <feather-icon
        @click="expropiateChat"
        icon="XCircleIcon"
        class="cursor-pointer text-danger pt-2"
        style="width:20px !important"
      ></feather-icon>
    </a>
  </td>
</template>
<script>
import konecta from '@/services/konecta'

import { mapState } from 'vuex'
export default {
  data() {
    return {
      toggleBot: false
    }
  },
  computed: {
    ...mapState(['activeChatUser', 'lang', 'languageSelected'])
  },
  methods: {
    expropiateChat() {
      konecta
        .post('/takeover/expropiate-takeover', {
          bot: this.activeChatUser.bot,
          senderId: this.activeChatUser.sender
        })
        .catch(error => {
          console.error('error', JSON.stringify(error, undefined, 2))
        })
    }
  }
}
</script>
