var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-prompt',{attrs:{"vs-title":(_vm.title + " 🤖"),"vs-active":_vm.active,"vs-accept-text":_vm.title,"vs-cancel-text":_vm.lang.chatTransitionSettings.cancel[_vm.languageSelected]},on:{"update:vsActive":function($event){_vm.active=$event},"vs-accept":_vm.finish,"vs-close":_vm.close,"vs-cancel":_vm.close}},[_c('div',{staticClass:"con-exemple-prompt"},[(
        _vm.test &&
          (!_vm.params ||
            (_vm.params.tagging === 'disable' &&
              _vm.params.comment === 'disable' &&
              _vm.params.transitionMessage === 'disable'))
      )?_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('vs-icon',{staticClass:"small",attrs:{"icon":"icon-info","icon-pack":"feather"}}),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.lang.chatTransitionSettings.settings.allOptionsAreDisabled[
              _vm.languageSelected
            ])+"\n        ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.params.tagging && _vm.params.tagging !== 'disable')?_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('p',{staticClass:"text-base mb-2"},[_vm._v("\n          "+_vm._s(_vm.lang.chatTransitionSettings.settings.tagging[_vm.languageSelected])+"\n          "+_vm._s(_vm.params.tagging === 'required' ? '*' : '')+"\n        ")]),_vm._v(" "),_c('multiselect',{class:{
            'mb-4': _vm.taggingError === null,
            'mb-0': _vm.taggingError !== null
          },staticStyle:{"z-index":"99999"},attrs:{"tag-placeholder":_vm.lang.chatTransitionSettings.transitions.tagging.placeholder[
              _vm.languageSelected
            ],"placeholder":_vm.lang.chatTransitionSettings.transitions.tagging.placeholder[
              _vm.languageSelected
            ],"selected-label":'select label',"deselect-label":'deselect label',"label":"name","track-by":"code","options":_vm.options,"multiple":_vm.params.taggingType === 'single' ? false : true,"taggable":false},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}),_vm._v(" "),(_vm.taggingError !== null)?_c('p',{staticClass:"mt-2 mb-4 text-danger"},[_vm._v("\n          "+_vm._s(_vm.taggingError)+"\n        ")]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.params.comment && _vm.params.comment !== 'disable')?_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('p',{staticClass:"text-base mb-2"},[_vm._v("\n          "+_vm._s(_vm.lang.chatTransitionSettings.settings.comment[_vm.languageSelected])+"\n          "+_vm._s(_vm.params.comment === 'required' ? '*' : '')+"\n        ")]),_vm._v(" "),_c('vs-textarea',{class:{
            'mb-4': _vm.commentError === null,
            'mb-2': _vm.commentError !== null
          },model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_vm._v(" "),(_vm.commentError !== null)?_c('p',{staticClass:"mb-4 text-danger"},[_vm._v("\n          "+_vm._s(_vm.commentError)+"\n        ")]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(
        _vm.params.transitionMessage &&
          _vm.params.transitionMessage !== 'disable' &&
          _vm.transitionMessage
      )?_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('p',{staticClass:"text-base mb-2"},[_vm._v("\n              "+_vm._s(_vm.lang.chatTransitionSettings.settings.defaultTransitionMessage[
                  _vm.languageSelected
                ])+"\n              "+_vm._s(_vm.params.transitionMessage === 'required' ? '*' : '')+"\n            ")]),_vm._v(" "),(
                _vm.params.transitionMessage &&
                  _vm.params.transitionMessage === 'optional'
              )?_c('vx-slider',{staticClass:"mb-3",model:{value:(_vm.transitionMessageSwitch),callback:function ($$v) {_vm.transitionMessageSwitch=$$v},expression:"transitionMessageSwitch"}}):_vm._e()],1)],1),_vm._v(" "),(_vm.transitionMessageSwitch)?_c('vs-textarea',{attrs:{"height":"100px","disabled":true},model:{value:(_vm.transitionMessage),callback:function ($$v) {_vm.transitionMessage=$$v},expression:"transitionMessage"}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{staticClass:"mt-2"},[(_vm.params.surveyEnabled)?_c('KonaAlert',{attrs:{"icon":"AlertCircleIcon","bg-color":"rgba(var(--vs-warning), 0.15)","color":"rgba(var(--vs-warning), 1)","size":"small"}},[_vm._v("\n          "+_vm._s(_vm.lang.chatTransitionSettings.transitions.surveyDisclaimer[
              _vm.languageSelected
            ])+"\n        ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }