<template>
  <td v-tooltip="lang.videoCall.tooltip[languageSelected]">
    <div>
      <VideoCallComponent
        ref="videoCall"
        :videoCallPopupTitle="popupTitle"
        :videoCallLink="videoCallLink"
      />
      <a>
        <feather-icon
          @click="startVideoCall"
          icon="VideoIcon"
          class="cursor-pointer text-white pt-2"
          style="width:20px !important"
        ></feather-icon>
      </a>
    </div>
  </td>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import VideoCallComponent from '../../../components/VideoCall'

import videoCallService from '../../../../../services/videocall.service.js'

export default {
  name: 'VideoCall',
  components: {
    VideoCallComponent
  },
  data() {
    return {
      videoCallLink: ''
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'videoCall',
      'activeChatUser',
      'config',
      'session'
    ]),
    popupTitle() {
      if (this.activeChatUser) {
        return this.activeChatUser.fullname
      }
      return ''
    }
  },
  methods: {
    ...mapMutations(['UPDATE_VIDEO_CALL_LINK']),
    ...mapActions(['sendTextMessage']),
    async getLink() {
      const { data } = await videoCallService.createVideoCall({
        chat: this.activeChatUser._id,
      })
      return {
        link: this.config.videocall + `/${data.roomId}`,
        videoCallId: data._id,
      }
    },
    async sendLink({ link, videoCallId }) {
      const simpleMessage = {
        bot: this.activeChatUser.bot,
        senderId: this.activeChatUser.sender,
        input: link,
        videoCallId,
      }
      try {
        this.$vs.loading()
        await this.sendTextMessage(simpleMessage)
        this.$vs.loading.close()
      } catch (ex) {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.attentionRequests.chatForm.msgError[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async openVideoCall({ link, videoCallId }) {
      if (link) {
        this.videoCallLink = link
      }
      if (videoCallId) {
        const { data } = await videoCallService.getVideoCall(videoCallId)
        this.videoCallLink = this.config.videocall + `/${data.roomId}?token=${data.userToken}`
      }
      this.$refs.videoCall.setVideoCallPopupOpen(true)
    },
    startVideoCall() {
      this.$vs.dialog({
        class: 'video-call',
        type: 'confirm',
        color: 'warning',
        title: this.lang.videoCall.confirm.title[this.languageSelected],
        text: this.lang.videoCall.confirm.text[this.languageSelected],
        acceptText: this.lang.videoCall.confirm.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.videoCall.confirm.cancelText[
          this.languageSelected
        ],
        accept: async () => {
          const { link, videoCallId }  = await this.getLink()
          this.sendLink({ link, videoCallId })
          this.openVideoCall({ videoCallId })
        }
      })
    }
  }
}
</script>
