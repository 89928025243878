<template>
  <td
    v-if="activeChatUser.status == 2"
    v-tooltip="lang.attentionRequests.navbar.transfer[languageSelected]"
  >
    <feather-icon
      @click="transition"
      icon="RepeatIcon"
      class="cursor-pointer text-white pt-2"
      style="width: 20px !important"
    ></feather-icon>

    <AttentionRequestTransition
      ref="transitionPrompt"
      v-if="attentionRequestTransitionParams"
      :params="attentionRequestTransitionParams"
      :title="lang.attentionRequests.navbar.transfer[languageSelected]"
      :accept="accept"
    />
  </td>
</template>
<script>
import konecta from '@/services/konecta'

import AttentionRequestTransition from '../../../attention-requests/transition/AttentionRequestTransition'

import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    AttentionRequestTransition
  },
  data() {
    return {
      attentionRequestTransitionParams: null
    }
  },
  computed: {
    ...mapState([
      'activeChatUser',
      'bot',
      'lang',
      'languageSelected',
      'chatTransitionSettingsScope',
      'chatTransitionSettings'
    ])
  },
  methods: {
    transition() {
      if (
        this.attentionRequestTransitionParams &&
        (this.attentionRequestTransitionParams.comment !== 'disable' ||
          this.attentionRequestTransitionParams.tagging !== 'disable' ||
          this.attentionRequestTransitionParams.transitionMessage !== 'disable')
      ) {
        this.$refs.transitionPrompt.open()
      } else {
        this.transfer()
      }
    },
    async accept(data) {
      try {
        if (
          (this.attentionRequestTransitionParams.transitionMessage ===
            'required' ||
            (this.attentionRequestTransitionParams.transitionMessage ===
              'optional' &&
              data &&
              data.transitionMessageSwitch)) &&
          this.attentionRequestTransitionParams.defaultTransitionMessage &&
          this.attentionRequestTransitionParams.defaultTransitionMessage.trim()
            .length > 0
        ) {
          // send default transition message
          const simpleMessage = {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender,
            input: this.attentionRequestTransitionParams.defaultTransitionMessage.trim()
          }
          this.$vs.loading()
          await this.sendTextMessage(simpleMessage)
          this.$vs.loading.close()
        }
        this.transfer(data)
      } catch (ex) {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.attentionRequests.chatForm.msgError[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    transfer(data) {
      // const session = localStorage.getItem('session')
      // const token = JSON.parse(session).token
      // this.$socket.client.emit('join', {
      //   type: 'control',
      //   token,
      //   service: this.bot.service
      // })
      konecta
        .post('/takeover/transfer-takeover', {
          bot: this.activeChatUser.bot,
          senderId: this.activeChatUser.sender,
          tags:
            data && data.tags
              ? data.tags instanceof Array
                ? data.tags.map(item => item.name)
                : [data.tags.name]
              : undefined,
          comment:
            data && data.comment && data.comment.trim().length > 0
              ? data.comment
              : undefined
        })
        .then(() => {
          this.UPDATE_ACTIVE_CHAT = null
          this.$emit('transfer')
        })
    },
    ...mapMutations(['UPDATE_ACTIVE_CHAT', 'SET_SUGGESTED_CHAT_MESSAGE']),
    ...mapActions(['sendTextMessage'])
  },
  watch: {},
  mounted() {
    if (
      this.chatTransitionSettingsScope === 'company' &&
      this.chatTransitionSettings &&
      this.chatTransitionSettings.transfer
    ) {
      this.attentionRequestTransitionParams = this.chatTransitionSettings.transfer
    }
  }
}
</script>
