var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',[_c('tr',[(
          _vm.config &&
            _vm.config.videocall &&
            _vm.videoCall &&
            _vm.videoCall.active &&
            _vm.activeChatUser &&
            _vm.activeChatUser.status === 2 &&
            !_vm.takedBy &&
            (_vm.showOption || _vm.showOptionStaffManagementVideoCall)
        )?_c("VideoCall",{tag:"td"}):_vm._e(),_vm._v(" "),(!_vm.takedBy && (_vm.showOption || _vm.showOptionStaffManagementTransfer))?_c("Transfer",{tag:"td",on:{"transfer":function($event){_vm.transfer = new Date()}}}):_vm._e(),_vm._v(" "),(!_vm.takedBy && (_vm.showOption || _vm.showOptionStaffManagementSwitchBot))?_c("SwitchBot",{tag:"td",attrs:{"transfer":_vm.transfer}}):_vm._e(),_vm._v(" "),(_vm.takedBy && (_vm.canStopTakeover || _vm.showOptionStaffManagementExpropiarChat) && (_vm.showOption || _vm.showOptionStaffManagementExpropiarChat))?_c("ExpropiarChat",{tag:"td"}):_vm._e(),_vm._v(" "),_c("Takeovers",_vm._b({tag:"td"},'td',{ userId: _vm.userId },false)),_vm._v(" "),_c("CopyView",{tag:"td"}),_vm._v(" "),_c("Export",{tag:"td"}),_vm._v(" "),_c("Maximize",{tag:"td"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }