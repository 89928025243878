<template>
  <td v-tooltip="title">
    <!-- <vx-tooltip :text="title" position="left"> -->
    <feather-icon
      :icon="icon"
      class="cursor-pointer text-white pt-2"
      style="width:20px !important"
      @click="MAXIMIZE_CHAT_CONTAINER"
    ></feather-icon>
    <!-- </vx-tooltip> -->
  </td>
  <!-- :svgClasses="[{'text-warning fill-current stroke-current': isPinnedLocal}, 'w-6', 'h-6']" -->
</template>
<script>
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['maximizeChatContainer', 'lang', 'languageSelected']),
    icon() {
      if (!this.maximizeChatContainer) return 'Maximize2Icon'
      if (this.maximizeChatContainer) return 'Minimize2Icon'
    },
    title() {
      if (!this.maximizeChatContainer)
        return this.lang.attentionRequests.navbar.maximize.on[
          this.languageSelected
        ]
      if (this.maximizeChatContainer)
        return this.lang.attentionRequests.navbar.maximize.off[
          this.languageSelected
        ]

      if (!this.maximizeChatContainer)
        return this.lang.attentionRequests.navbar.maximize.on[
          this.languageSelected
        ]
      if (this.maximizeChatContainer)
        return this.lang.attentionRequests.navbar.maximize.off[
          this.languageSelected
        ]
    }
  },
  methods: {
    ...mapMutations(['MAXIMIZE_CHAT_CONTAINER'])
  }
}
</script>
