<template>
  <vs-prompt
    :vs-title="`${title} 🤖`"
    :vs-active.sync="active"
    :vs-accept-text="title"
    :vs-cancel-text="lang.chatTransitionSettings.cancel[languageSelected]"
    @vs-accept="finish"
    @vs-close="close"
    @vs-cancel="close"
  >
    <div class="con-exemple-prompt">
      <div
        class="vx-row"
        v-if="
          test &&
            (!params ||
              (params.tagging === 'disable' &&
                params.comment === 'disable' &&
                params.transitionMessage === 'disable'))
        "
      >
        <vs-col>
          <vs-icon class="small" icon="icon-info" icon-pack="feather"></vs-icon>
          <p>
            {{
              lang.chatTransitionSettings.settings.allOptionsAreDisabled[
                languageSelected
              ]
            }}
          </p>
        </vs-col>
      </div>
      <div class="vx-row" v-if="params.tagging && params.tagging !== 'disable'">
        <vs-col>
          <p class="text-base mb-2">
            {{ lang.chatTransitionSettings.settings.tagging[languageSelected] }}
            {{ params.tagging === 'required' ? '*' : '' }}
          </p>
          <multiselect
            :class="{
              'mb-4': taggingError === null,
              'mb-0': taggingError !== null
            }"
            style="z-index: 99999;"
            v-model="tags"
            :tag-placeholder="
              lang.chatTransitionSettings.transitions.tagging.placeholder[
                languageSelected
              ]
            "
            :placeholder="
              lang.chatTransitionSettings.transitions.tagging.placeholder[
                languageSelected
              ]
            "
            :selected-label="'select label'"
            :deselect-label="'deselect label'"
            label="name"
            track-by="code"
            :options="options"
            :multiple="params.taggingType === 'single' ? false : true"
            :taggable="false"
          ></multiselect>
          <p class="mt-2 mb-4 text-danger" v-if="taggingError !== null">
            {{ taggingError }}
          </p>
        </vs-col>
      </div>
      <div class="vx-row" v-if="params.comment && params.comment !== 'disable'">
        <vs-col>
          <p class="text-base mb-2">
            {{ lang.chatTransitionSettings.settings.comment[languageSelected] }}
            {{ params.comment === 'required' ? '*' : '' }}
          </p>
          <vs-textarea
            :class="{
              'mb-4': commentError === null,
              'mb-2': commentError !== null
            }"
            v-model="comment"
          />
          <p class="mb-4 text-danger" v-if="commentError !== null">
            {{ commentError }}
          </p>
        </vs-col>
      </div>

      <div
        class="vx-row"
        v-if="
          params.transitionMessage &&
            params.transitionMessage !== 'disable' &&
            transitionMessage
        "
      >
        <vs-col>
          <div class="vx-row">
            <vs-col>
              <p class="text-base mb-2">
                {{
                  lang.chatTransitionSettings.settings.defaultTransitionMessage[
                    languageSelected
                  ]
                }}
                {{ params.transitionMessage === 'required' ? '*' : '' }}
              </p>
              <vx-slider
                v-if="
                  params.transitionMessage &&
                    params.transitionMessage === 'optional'
                "
                v-model="transitionMessageSwitch"
                class="mb-3"
              />
            </vs-col>
          </div>

          <vs-textarea
            height="100px"
            v-if="transitionMessageSwitch"
            v-model="transitionMessage"
            :disabled="true"
          />
        </vs-col>
      </div>
      <div class="vx-row">
        <vs-col class="mt-2">
          <KonaAlert
            v-if="params.surveyEnabled"
            icon="AlertCircleIcon"
            bg-color="rgba(var(--vs-warning), 0.15)"
            color="rgba(var(--vs-warning), 1)"
            size="small"
          >
            {{
              lang.chatTransitionSettings.transitions.surveyDisclaimer[
                languageSelected
              ]
            }}
          </KonaAlert>
        </vs-col>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import Multiselect from 'vue-multiselect'

import { mapState } from 'vuex'

export default {
  props: {
    title: {
      type: String
    },
    params: {
      type: Object
    },
    accept: {
      type: Function
    },
    test: {
      type: Boolean
    }
  },
  components: {
    Multiselect,
    KonaAlert: () => import('@/components/KonaAlert.vue')
  },
  data() {
    return {
      active: false,
      tags: [],
      options: [],
      comment: '',
      transitionMessage: '',
      transitionMessageSwitch: true,
      dontShowTaggingError: true, // Para no mostrar los mensajes de error de una
      dontShowCommentError: true // Para no mostrar los mensajes de error de una
    }
  },
  watch: {
    tagsSting() {
      this.dontShowTaggingError = false
    },
    comment() {
      this.dontShowCommentError = false
    },
    params() {
      this.updateOptions()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    tagsSting() {
      return JSON.stringify(this.tags)
    },
    taggingError() {
      if (
        !this.dontShowTaggingError &&
        this.params.tagging === 'required' &&
        (!this.tags || this.tags.length === 0)
      ) {
        return this.lang.chatTransitionSettings.transitions.errors
          .taggingRequired[this.languageSelected]
      }
      return null
    },
    commentError() {
      if (
        !this.dontShowCommentError &&
        this.params.comment === 'required' &&
        (!this.comment || this.comment.trim().length === 0)
      ) {
        return this.lang.chatTransitionSettings.transitions.errors
          .commentRequired[this.languageSelected]
      }
      return null
    },
    isValid() {
      let result = true
      if (
        this.params.tagging === 'required' &&
        (!this.tags || this.tags.length === 0)
      ) {
        result = false
      }
      if (
        this.params.comment === 'required' &&
        (!this.comment || this.comment.trim().length === 0)
      ) {
        result = false
      }
      return result
    }
  },
  methods: {
    open() {
      this.active = true
      this.tags = []
      this.transitionMessage = this.params.defaultTransitionMessage
      this.comment = ''
      this.transitionMessageSwitch = true
      this.$nextTick(() => {
        this.dontShowTaggingError = true
        this.dontShowCommentError = true
      })
    },
    close() {
      this.active = false
    },
    finish() {
      if (!this.isValid) {
        this.active = true
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.chatTransitionSettings.transitions.errors
            .rejectAccept[this.languageSelected],
          color: 'danger'
        })
        this.dontShowTaggingError = false
        this.dontShowCommentError = false
        return
      }
      if (!this.accept) {
        this.close()
        return
      }
      this.accept({
        tags: this.tags,
        comment: this.comment,
        transitionMessageSwitch: this.transitionMessageSwitch
      })
      this.close()
    },
    updateOptions() {
      if (this.params && this.params.tags) {
        this.options = this.params.tags.map((item, index) => ({
          code: index,
          name: item
        }))
      }
    }
  },
  mounted() {
    this.updateOptions()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.vs-con-textarea {
  margin-bottom: 0px;
}
</style>

<style lang="scss">
.con-vs-dialog .vs-dialog footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

.con-vs-dialog .vs-dialog footer .vs-button {
  margin-left: 0.5rem;
}
</style>
