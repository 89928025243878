<template>
  <td @click.stop.prevent="switchClick">
    <RockerSwitch
      v-tooltip="title"
      :labelOn="'🤖'"
      :labelOff="'👨‍💻'"
      :size="0.7"
      :value="toggleBot"
      :borderColor="getSwitchBorderColor"
      :toggle="true"
      :key="key"
      @change="switchChange"
      ref="RockerSwitch"
    />
    <AttentionRequestTransition
      ref="transitionPrompt"
      v-if="attentionRequestTransitionParams"
      :params="attentionRequestTransitionParams"
      :title="title"
      :accept="accept"
    />
  </td>
</template>
<script>
import konecta from '@/services/konecta'

import RockerSwitch from 'vue-rocker-switch'
// Import styles
import 'vue-rocker-switch/dist/vue-rocker-switch.css'

import AttentionRequestTransition from '../../../attention-requests/transition/AttentionRequestTransition'

import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: ['transfer'],
  components: {
    RockerSwitch,
    AttentionRequestTransition
  },
  data() {
    return {
      toggleBot: null,
      key: new Date().getTime(),
      transferEvent: false,
      attentionRequestTransitionParams: null
    }
  },
  methods: {
    switchClick() {/* switchClick */},

    async accept(data) {
      try {
        if (
          (this.attentionRequestTransitionParams.transitionMessage ===
            'required' ||
            (this.attentionRequestTransitionParams.transitionMessage ===
              'optional' &&
              data &&
              data.transitionMessageSwitch)) &&
          this.attentionRequestTransitionParams.defaultTransitionMessage &&
          this.attentionRequestTransitionParams.defaultTransitionMessage.trim()
            .length > 0
        ) {
          // send default transition message
          const simpleMessage = {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender,
            input: this.attentionRequestTransitionParams.defaultTransitionMessage.trim()
          }
          this.$vs.loading()
          await this.sendTextMessage(simpleMessage)
          this.$vs.loading.close()
        }
        this.endTakeover(data)
      } catch (ex) {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.attentionRequests.chatForm.msgError[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      }
    },

    endTakeover(data) {
      this.$vs.loading()
      konecta
        .post('/takeover/end-takeover', {
          bot: this.activeChatUser.bot,
          senderId: this.activeChatUser.sender,
          tags:
            data && data.tags
              ? data.tags instanceof Array
                ? data.tags.map(item => item.name)
                : [data.tags.name]
              : undefined,
          comment:
            data && data.comment && data.comment.trim().length > 0
              ? data.comment
              : undefined
        })
        .then(() => {
          if (this.plugins.KBASE.active) {
            this.KBASEForm()
          }
          this.UPDATE_ACTIVE_CHAT(null)
        })
        .finally(() => this.$vs.loading.close())
    },
    async switchChange(newVal) {
      if (this.transferEvent === true) {
        this.transferEvent = false
        return
      }
      if (newVal === true && this.activeChatUser.status === 2) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.lang.attentionRequests.confirmTakeover.title[
            this.languageSelected
          ],
          text: this.lang.attentionRequests.confirmTakeover.text[
            this.languageSelected
          ],
          acceptText: this.lang.attentionRequests.confirmTakeover.acceptText[
            this.languageSelected
          ],
          cancelText: this.lang.attentionRequests.confirmTakeover.cancelText[
            this.languageSelected
          ],
          buttonAccept: 'filled',
          buttonCancel: 'filled',
          accept: () => {
            if (
              this.attentionRequestTransitionParams &&
              (this.attentionRequestTransitionParams.comment !== 'disable' ||
                this.attentionRequestTransitionParams.tagging !== 'disable' ||
                this.attentionRequestTransitionParams.transitionMessage !==
                  'disable')
            ) {
              this.$refs.transitionPrompt.open()
            } else {
              this.endTakeover()
            }
          }
        })
      }
      if (
        newVal === false &&
        this.toggleBot &&
        this.activeChatUser.status !== 2
      ) {
        this.toggleBot = false
        try {
          await konecta.post('/takeover/start-takeover', {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender
          })
          this.TAKEOVER_PENDING(this.activeChatUser)
        } catch (error) {
          console.log(error)
          this.toggleBot = true
        }
      }
    },
    verifyBotStatus(status) {
      this.transferEvent = false
      if (status != 2) this.toggleBot = true
      if (status == 2) this.toggleBot = false
    },
    KBASEForm() {
      let plugins = this.plugins
      plugins.KBASE.data.activeModal = true
      this.UPDATE_PLUGINS(plugins)
    },
    update() {
      // console.log('update')
      // this.$forceUpdate()
      // this.verifyBotStatus(this.activeChatUser.status)
    },
    ...mapMutations([
      'UPDATE_ACTIVE_CHAT',
      'UPDATE_PLUGINS',
      'TAKEOVER_PENDING'
    ]),
    ...mapActions(['sendTextMessage'])
  },
  computed: {
    ...mapState([
      'activeChatUser',
      'bot',
      'lang',
      'languageSelected',
      'chatTransitionSettingsScope',
      'chatTransitionSettings',
      'plugins',
    ]),
    getSwitchBorderColor() {
      switch (this.activeChatUser.status) {
        case 0:
          return '#cf847f'
        case 1:
          return '#fcb169'
        case 2:
          return '#5fd190'
        case 3:
          return '#ed8081'
        default:
          return 'grey'
      }
    },
    title() {
      if (this.activeChatUser.status != 2)
        return this.lang.attentionRequests.navbar.switch.title.active[
          this.languageSelected
        ]
      if (this.activeChatUser.status == 2)
        return this.lang.attentionRequests.navbar.switch.title.inactive[
          this.languageSelected
        ]
    },
    chip() {
      if (this.activeChatUser.status != 2)
        return this.lang.attentionRequests.navbar.switch.chip.active[
          this.languageSelected
        ]
      if (this.activeChatUser.status == 2)
        return this.lang.attentionRequests.navbar.switch.chip.inactive[
          this.languageSelected
        ]
    },
    chatStatusColor() {
      if (this.activeChatUser.status == 1) return 'warning'
      if (this.activeChatUser.status == 2) return 'success'
      if (this.activeChatUser.status == 3) return 'danger'
    },
    activeChatUserStatus() {
      if (this.activeChatUser.status != 2) {
        return false
      }
      if (this.activeChatUser.status == 2) {
        return true
      }
    },
  },
  watch: {
    getSwitchBorderColor() {
      this.key = new Date().getTime()
    },
    activeChatUser() {
      this.verifyBotStatus(this.activeChatUser.status)
    },
    transfer() {
      this.transferEvent = true
      this.toggleBot = true
    }
  },
  mounted() {
    this.verifyBotStatus(this.activeChatUser.status)
    if (
      this.chatTransitionSettingsScope === 'company' &&
      this.chatTransitionSettings &&
      this.chatTransitionSettings.finish
    ) {
      this.attentionRequestTransitionParams = this.chatTransitionSettings.finish
    }
  }
}
</script>
<style lang="scss">
.switch-chip {
  margin: 0;
  min-height: 23px;
  margin-right: 20px;
}

.vs-dialog {
  footer {
    .vs-button:last-of-type {
      background: rgba(var(--vs-danger), 1) !important;
    }
  }
}
</style>
