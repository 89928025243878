<template>
  <div>
    <table>
      <tr>
        <td
          v-if="
            config &&
              config.videocall &&
              videoCall &&
              videoCall.active &&
              activeChatUser &&
              activeChatUser.status === 2 &&
              !takedBy &&
              (showOption || showOptionStaffManagementVideoCall)
          "
          is="VideoCall"
        ></td>
        <td
          v-if="!takedBy && (showOption || showOptionStaffManagementTransfer)"
          is="Transfer"
          @transfer="transfer = new Date()"
        ></td>
        <td
          v-if="!takedBy && (showOption || showOptionStaffManagementSwitchBot)"
          is="SwitchBot"
          :transfer="transfer"
        ></td>
        <td
          v-if="takedBy && (canStopTakeover || showOptionStaffManagementExpropiarChat) && (showOption || showOptionStaffManagementExpropiarChat)"
          is="ExpropiarChat"
        ></td>
        <td is="Takeovers" v-bind="{ userId }"></td>
        <td is="CopyView"></td>
        <td is="Export"></td>
        <td is="Maximize"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Maximize from './Maximize'
import SwitchBot from './SwitchBot'
import Transfer from './Transfer'
import Export from './Export'
import ExpropiarChat from './ExpropiarChat'
import VideoCall from './VideoCall'
import { USER_ROLES } from '../../../../../models/Roles/UserRoles'

import staffManagementMixin from '@/mixins/staffManagementMixin'

const noTakeoverChannels = ['alexa', 'voice'];

export default {
  props: {
    canStopTakeover: {
      type: Boolean,
      required: true
    },
    takedBy: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    Maximize,
    SwitchBot,
    Transfer,
    Export,
    ExpropiarChat,
    VideoCall,
    CopyView: () => import('./CopyView'),
    Takeovers: () => import('./Takeovers.vue')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      transfer: ''
    }
  },
  computed: {
    ...mapState([
      'config',
      'activeChatUser',
      'videoCall',
      'lang',
      'languageSelected',
      'session',
      'useStaffManagement'
    ]),
    queryChatOtherUser() {
      return (
        this.$route.query.chat &&
        this.$route.query.chat !== this.activeChatUser._id
      )
    },
    activeByOtherUser() {
      return (
        this.activeChatUser.using &&
        this.activeChatUser.using._id &&
        this.activeChatUser.using._id !== this.$store.state.session.user.id
      )
    },
    isSuperadmin() {
      return (
        this.$store.state.session.user.roles.ids.indexOf(
          USER_ROLES.SUPER_ADMIN
        ) > -1
      )
    },
    isSupervisor() {
      return (
        this.$store.state.session.user.roles.ids.indexOf(
          USER_ROLES.SUPERVISOR
        ) > -1
      )
    },
    showOptionStaffManagementVideoCall() {
      const result = this.isAuthAux('konecta.pending_chats.video_call', false)
      return (
        result &&
        !(noTakeoverChannels.indexOf(this.activeChatUser.platform) > -1)
      )
    },
    showOptionStaffManagementTransfer() {
      const result = this.isAuthAux('konecta.pending_chats.release_chat', false)
      console.log('result :::: release', result)
      return (
        result &&
        !(noTakeoverChannels.indexOf(this.activeChatUser.platform) > -1)
      )
    },
    showOptionStaffManagementSwitchBot() {
      const result = this.isAuthAux('konecta.pending_chats.take_chat', false)
      return (
        result &&
        !(noTakeoverChannels.indexOf(this.activeChatUser.platform) > -1)
      )
    },
    showOptionStaffManagementExpropiarChat() {
      const result = this.isAuthAux('konecta.pending_chats.expropiate', false)
      return (
        result &&
        !(noTakeoverChannels.indexOf(this.activeChatUser.platform) > -1)
      )
    },
    showOption() {
      /**
       * *1: superadmin can take supervisors & agents chats
       * *2: supervisors can take agents chats
       */
      const result =
        !(
          this.$route.query.bot &&
          !this.queryChatOtherUser &&
          this.activeByOtherUser
        ) ||
        // *2
        (this.isSupervisor &&
          this.activeChatUser &&
          this.activeChatUser.status === 2 &&
          this.activeChatUser.using &&
          this.activeChatUser.using.roles &&
          this.activeChatUser.using.roles.indexOf(USER_ROLES.AGENT) > -1) ||
        // *1
        this.isSuperadmin
      return (
        result &&
        !(noTakeoverChannels.indexOf(this.activeChatUser.platform) > -1)
        && !this.useStaffManagement
      )
    }
  }
}
</script>

<style scoped>
td {
  padding: 0 10px;
}
</style>
